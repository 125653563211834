<template>
  <v-menu
    offset-y
    transition="scale-transition"
    origin="center center"
    bottom
    left
    rounded="lg"
  >
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-badge
          v-if="uniqueCategoryCount > 0"
          color="error"
          offset-x="9"
          offset-y="9"
        >
          <template v-slot:badge>
            <span>{{ uniqueCategoryCount }}</span>
          </template>
          <v-icon color="rgba(17, 76, 172, 1)" v-bind="attrs" v-on="on"
            >mdi-bell</v-icon
          >
        </v-badge>

        <v-icon v-else color="rgba(17, 76, 172, 1)" v-bind="attrs" v-on="on"
          >mdi-bell</v-icon
        >
      </div>
    </template>
    <v-list>
      <v-list-item
        class="tw-border-b tw-border-gray-200 tw-border-spacing-2 tw-m-4"
      >
        <span class="title">Notifications</span>
      </v-list-item>
    </v-list>

    <v-list v-if="items.length === 0">
      <v-list-item>
        <div class="tw-flex tw-items-center tw-justify-center tw-mx-4">
          <span class="tw-font-bold">You have no notifications</span>
        </div>
      </v-list-item>

      <v-list-item>
        <div class="tw-flex tw-items-center tw-justify-center tw-mx-4">
          <span class=""
            >Great news! You have no unread notifications at this time.<br />
            Come back later for any updates.</span
          >
        </div>
      </v-list-item>
    </v-list>

    <v-list
      class="tw-max-w-md"
      v-for="(item, i) in oneItemPerCategory"
      :key="i"
    >
      <v-list-item
        class="tw-h-auto text-wrap tw-flex tw-items-center"
        v-bind:class="{ 'tw-bg-gray-100 tw-cursor-pointer': item.read === 1 }"
        v-on:mouseover="item.read = 1"
        v-on:mouseleave="item.read = 0"
        v-if="items.length > 0"
        @click="
          markAsRead(getCategoryLink(item), getIdsByCategory(item.category))
        "
      >
        <div
          class="
            color
            tw-mr-2
            -tw-mt-3
            tw-w-8
            tw-h-8
            tw-flex
            tw-justify-center
            tw-items-center
            tw-rounded-full
            tw-overflow-hidden
            tw-flex-shrink-0
            tw-p-2
          "
          :style="{ backgroundColor: getColor(item) }"
        >
          <span class="tw-text-white">{{ getAbbreviation(item) }}</span>
        </div>
        <div class="tw-flex tw-flex-col tw-justify-center tw-w-full">
          <span class="tw-text tw-font-semibold tw-mb-1"
            >You have ({{ countByCategory(item.category) }}) new
            {{ getCategoryName(item.category) }}
          </span>
          <span class="tw-text tw-text-gray-500">{{ item.notification }}</span>
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from 'axios'
export default {
  beforeMount() {
    this.fetchUnreadNotifications(this.$store.state.auth.user.sub)
  },
  data: () => ({
    notifications: [],
  }),
  computed: {
    items() {
      return this.notifications
    },
    oneItemPerCategory() {
      const categoryCounts = this.items.reduce((counts, item) => {
        if (counts[item.category]) {
          counts[item.category]++
        } else {
          counts[item.category] = 1
        }
        return counts
      }, {})
      return Object.keys(categoryCounts).map((category) => {
        return this.items.find((item) => item.category === category)
      })
    },
    uniqueCategoryCount() {
      const categoryCounts = this.items.reduce((counts, item) => {
        if (counts[item.category]) {
          counts[item.category]++
        } else {
          counts[item.category] = 1
        }
        return counts
      }, {})
      return Object.keys(categoryCounts).length
    },
  },
  methods: {
    async fetchUnreadNotifications(userId) {
      await axios
        .get(`/motor-assessment/api/notifications/unread/${userId}`)
        .then((response) => {
          this.notifications = response.data.data
        })
        .catch((error) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: error.message ?? 'Failed to load notifications',
            variant: 'error',
          })
        })
    },
    countByCategory(category) {
      return this.notifications.filter((item) => item.category === category)
        .length
    },
    getCategoryName(category) {
      switch (category) {
        case 'INT_ASSESSOR_INSPECTION':
          return 'Inspection(s)'
        case 'INT_ASSESSOR_SUPPLEMENTARY_GARAGE':
          return 'Supplementary Report(s)'
        case 'INT_ASSESSOR_SUPPLEMENTARY_EXT':
          return 'Supplementary External(s)'
        case 'EXT_ASSESSOR_BID_TEMPLATE':
          return 'Bid Template(s)'
        case 'EXT_ASSESSOR_SUPPLEMENTARY':
          return 'Supplementary Report(s)'
        case 'EXT_ASSESSOR_REINSPECTION':
          return 'Re-inspection Task(s)'
        case 'TECHNICIAN_CHECKOUT':
          return 'Checkout Task(s)'
        default:
          return 'Notification(s)'
      }
    },
    getAbbreviation(item) {
      switch (item.category) {
        case 'INT_ASSESSOR_INSPECTION':
          return 'IN'
        case 'INT_ASSESSOR_SUPPLEMENTARY_GARAGE':
          return 'SP'
        case 'INT_ASSESSOR_SUPPLEMENTARY_EXT':
          return 'SP'
        case 'EXT_ASSESSOR_BID_TEMPLATE':
          return 'BT'
        case 'EXT_ASSESSOR_SUPPLEMENTARY':
          return 'SP'
        case 'EXT_ASSESSOR_REINSPECTION':
          return 'RE'
        case 'TECHNICIAN_CHECKOUT':
          return 'CO'
        default:
          return 'N/A'
      }
    },
    getCategoryLink(item) {
      switch (item.category) {
        case 'INT_ASSESSOR_INSPECTION':
          return '/vehicle-inspection'
        case 'INT_ASSESSOR_SUPPLEMENTARY_GARAGE':
          return '/vehicle-inspection'
        case 'INT_ASSESSOR_SUPPLEMENTARY_EXT':
          return '/vehicle-inspection'
        case 'EXT_ASSESSOR_BID_TEMPLATE':
          return '/major-accident'
        case 'EXT_ASSESSOR_SUPPLEMENTARY':
          return '/major-accident'
        case 'EXT_ASSESSOR_REINSPECTION':
          return '/major-accident'
        case 'TECHNICIAN_CHECKOUT':
          return '/bids-submitted/vehicle-checkout'
        default:
          return '/dashboard'
      }
    },
    getColor(item) {
      switch (item.category) {
        case 'INT_ASSESSOR_INSPECTION':
          return 'rgba (93, 149, 242, 1)'
        case 'INT_ASSESSOR_SUPPLEMENTARY_GARAGE':
          return 'rgba (237, 99,0, 1)'
        case 'INT_ASSESSOR_SUPPLEMENTARY_EXT':
          return 'rgba(237, 99, 0, 1)'
        case 'EXT_ASSESSOR_BID_TEMPLATE':
          return 'rgba(255, 165, 0, 1)'
        case 'EXT_ASSESSOR_SUPPLEMENTARY':
          return 'rgba(237, 99, 0, 1)'
        case 'EXT_ASSESSOR_REINSPECTION':
          return 'rgba (93, 149, 242, 1)'
        case 'TECHNICIAN_CHECKOUT':
          return 'rgba (141, 58,148, 1)'
        default:
          return 'grey'
      }
    },

    getIdsByCategory(category) {
      return (
        this.notifications
          .filter((item) => item.category === category)
          .map((item) => item.id) || []
      )
    },

    markAsRead(categoryLink, ids) {
      this.$router.push(categoryLink)
      axios
        .put(`/motor-assessment/api/notifications/update-read-status`, ids)
        .then(() => {
          this.fetchUnreadNotifications(this.$store.state.auth.user.sub)
        })
        .catch((error) => {
          this.$store.commit('SNACKBAR', {
            state: 'failed',
            message: error.message ?? 'Failed to mark as read',
            variant: 'error',
          })
        })
    },
  },
}
</script>

<style scoped>
.color {
  background-color: #ff5252;
}
</style>
